<template>
  <!-- 录入讲师 -->
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)" />

    <div class="main-content">
      <div class="body">
        <a-steps :current="stepsCurrent">
          <a-step
            v-for="item in steps"
            :key="item.title"
            :title="item.title"
            :description="item.description"
          />
        </a-steps>

        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 14 }"
          v-if="stepsCurrent < 2"
        >
          <a-form-model-item
            label="讲师真实人像（用作头像）"
            prop="avatar"
            v-if="stepsCurrent === 0"
          >
            <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="uploadUrl"
              :data="{
                token: qiniuData.token
              }"
              :before-upload="beforeAvatarUpload"
              @change="handleAvatarChange"
            >
              <img
                class="avatar"
                :src="form.avatar"
                alt="avatar"
                v-if="form.avatar"
              />
              <div v-else>
                <a-icon :type="avatarLoading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item
            ref="jobNumber"
            label="讲师编号"
            prop="jobNumber"
            v-if="stepsCurrent === 0"
          >
            <a-input
              v-model="form.jobNumber"
              placeholder="请输入讲师编号"
              @blur="
                () => {
                  $refs.jobNumber.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item
            ref="realName"
            label="讲师真实姓名："
            prop="realName"
            v-if="stepsCurrent === 0"
          >
            <a-input
              v-model="form.realName"
              placeholder="请输入讲师真实姓名"
              @blur="
                () => {
                  $refs.realName.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item
            ref="mobile"
            label="手机号"
            prop="mobile"
            v-if="stepsCurrent === 0"
          >
            <a-input
              v-model="form.mobile"
              placeholder="请输入手机号"
              @blur="
                () => {
                  $refs.mobile.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item
            label="常住地址"
            prop="address"
            v-if="stepsCurrent === 0"
          >
            <a-select
              allow-clear
              show-search
              placeholder="请输入常驻地址"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              @search="handleAddressChange"
              @change="handleAddressChange"
              :not-found-content="null"
              v-model="form.address"
            >
              <a-select-option
                v-for="(d, index) in addressReturn"
                :key="d.value + index"
              >
                {{ d.value }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <template v-if="stepsCurrent === 1">
            <a-form-model-item
              ref="IDcardNum"
              label="身份证号码"
              prop="IDcardNum"
            >
              <a-input
                v-model="form.IDcardNum"
                placeholder="请输入18位身份证号码"
                @blur="
                  () => {
                    $refs.IDcardNum.onFieldBlur()
                  }
                "
              />
            </a-form-model-item>
            <div class="ID-card">
              <a-form-model-item
                prop="IDcardBackUrl"
                label="身份证图像"
                :label-col="{ span: 9 }"
                :wrapper-col="{ span: 14 }"
              >
                <a-upload
                  name="file"
                  list-type="picture-card"
                  class="IDcard-uploader"
                  :show-upload-list="false"
                  :action="uploadUrl"
                  :before-upload="beforeIdCardUpdate"
                  :data="{
                    token: qiniuData.token
                  }"
                  v-model="form.IDcardBackUrl"
                  @change="handleIDcardBackChange"
                >
                  <div v-if="form.IDcardBackUrl">
                    <img class="img" :src="form.IDcardBackUrl" />
                  </div>
                  <div v-else>
                    <a-icon :type="IDcardBackLoading ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">
                      上传身份证人像面
                    </div>
                  </div>
                </a-upload>
              </a-form-model-item>
              <a-form-model-item
                prop="IDcardFrontUrl"
                :label-col="{ span: 0 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-upload
                  name="file"
                  list-type="picture-card"
                  class="IDcard-uploader"
                  :show-upload-list="false"
                  :action="uploadUrl"
                  :before-upload="beforeIdCardUpdate"
                  :data="{
                    token: qiniuData.token
                  }"
                  v-model="form.IDcardFrontUrl"
                  @change="handleIDcardFrontChange"
                >
                  <div v-if="form.IDcardFrontUrl">
                    <img class="img" :src="form.IDcardFrontUrl" />
                  </div>
                  <div v-else>
                    <a-icon :type="IDcardFrontLoading ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">
                      上传身份证国徽面
                    </div>
                  </div>
                </a-upload>
              </a-form-model-item>
            </div>
            <div class="ID-time">
              <div class="ID-time-label">身份证有效期</div>
              <a-form-model-item
                label=""
                prop="IDcardStartTime"
                :label-col="{ span: 0 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-date-picker
                  v-model="form.IDcardStartTime"
                  placeholder="开始日期"
                  @change="onStartTimeChange"
                />
              </a-form-model-item>
              <a-form-model-item
                class="date-picker-2"
                :prop="IDcardEndTimeProp"
                :label-col="{ span: 0 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-date-picker
                  v-model="form.IDcardEndTime"
                  placeholder="结束日期"
                  @change="onEndTimeChange"
                  :disabled="timeCheckboxValue"
                />
              </a-form-model-item>
              <a-form-model-item
                class="checkbox"
                prop="checkbox"
                :label-col="{ span: 0 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-checkbox
                  v-model="timeCheckboxValue"
                  @change="onTimeCheckChange"
                >
                  长期
                </a-checkbox>
              </a-form-model-item>
            </div>
          </template>

          <!-- <a-form-model-item
            ref="IDcardNum"
            label="身份证号码"
            prop="IDcardNum"
            v-if="stepsCurrent === 1"
          >
            <a-input
              v-model="form.IDcardNum"
              placeholder="请输入18位身份证号码"
              @blur="
                () => {
                  $refs.IDcardNum.onFieldBlur()
                }
              "
            />
          </a-form-model-item>

          <a-form-model-item
            prop="IDcardBackUrl"
            label="身份证人像面"
            extra="建议横向拍摄，证件信息必须清晰可见"
            v-if="stepsCurrent === 1"
          >
            <a-upload
              name="file"
              list-type="picture-card"
              class="IDcard-uploader"
              :show-upload-list="false"
              :action="uploadUrl"
              :before-upload="beforeIdCardUpdate"
              :data="{
                token: qiniuData.token
              }"
              v-model="form.IDcardBackUrl"
              @change="handleIDcardBackChange"
            >
              <div v-if="form.IDcardBackUrl">
                <img class="img" :src="form.IDcardBackUrl" />
              </div>
              <div v-else>
                <a-icon :type="IDcardBackLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传身份证人像面
                </div>
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item
            prop="IDcardFrontUrl"
            label="身份证国徽面"
            extra="建议横向拍摄，证件信息必须清晰可见"
            v-if="stepsCurrent === 1"
          >
            <a-upload
              name="file"
              list-type="picture-card"
              class="IDcard-uploader"
              :show-upload-list="false"
              :action="uploadUrl"
              :before-upload="beforeIdCardUpdate"
              :data="{
                token: qiniuData.token
              }"
              v-model="form.IDcardFrontUrl"
              @change="handleIDcardFrontChange"
            >
              <div v-if="form.IDcardFrontUrl">
                <img class="img" :src="form.IDcardFrontUrl" />
              </div>
              <div v-else>
                <a-icon :type="IDcardFrontLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传身份证国徽面
                </div>
              </div>
            </a-upload>
          </a-form-model-item> -->

          <a-form-model-item class="submit-btn">
            <a-button
              type="primary"
              @click="onSubmit"
              v-if="stepsCurrent < 2"
              :loading="addLoading"
            >
              下一步
            </a-button>
          </a-form-model-item>
        </a-form-model>

        <template v-else>
          <div class="bind-status" v-if="qrcodeScanStatu">
            <div class="icon-box">
              <a-icon
                theme="filled"
                :style="{ color: '#FF4D4F', fontSize: '70px' }"
                type="info-circle"
              />
            </div>
            <div class="bind-title">已绑定微信，账号待开通</div>
            <div class="bind-p">请在联系公司后台负责人开通账号</div>
            <div class="btn-box">
              <a-button class="bind-btn" @click="$router.go(-2)">
                返回
              </a-button>
            </div>
          </div>
          <template v-else>
            <div class="qrcode-box">
              <div class="qrcode">
                <iframe
                  id="iframe"
                  :src="wechatLink"
                  width="300px"
                  height="400px"
                  frameborder="0"
                  name="iframea"
                ></iframe>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Debounce } from '../../utils/public'
let IDcardStartTime = ''
let IDcardEndTime = ''
export default {
  data() {
    return {
      title: '',
      stepsCurrent: 1,
      steps: [
        {
          title: '基础信息',
          description: '基础信息'
        },
        {
          title: '实名信息',
          description: '身份证号/图片实名信息'
        },
        {
          title: '绑定微信号',
          description: '用于中台的账号登录'
        }
      ],
      form: {
        realName: '',
        avatar: '',
        mobile: '',
        IDcardNum: '',
        IDcardFrontUrl: '',
        IDcardBackUrl: '',
        address: undefined,
        jobNumber: '',
        lectortype: 1
      },
      IDcardEndTimeProp: 'IDcardEndTime',
      rules: {
        realName: [
          { required: true, message: '请输入讲师真实姓名', trigger: 'blur' }
        ],
        avatar: [
          { required: true, message: '请上传讲师真实人像', trigger: 'change' }
        ],
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        jobNumber: [
          { required: true, message: '请输入讲师编号', trigger: 'blur' }
        ],
        IDcardNum: [
          { required: true, message: '请输入身份证号码', trigger: 'blur' }
        ],
        IDcardStartTime: [
          { required: true, message: '请输入开始日期', trigger: 'change' }
        ],
        IDcardEndTime: [
          { required: true, message: '请输入结束日期', trigger: 'change' }
        ],
        IDcardFrontUrl: [
          { required: true, message: '请上传身份证国徽面', trigger: 'change' }
        ],
        IDcardBackUrl: [
          { required: true, message: '请上传身份证人像面', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请输入常驻地址', trigger: 'change' }
        ]
      },
      avatarLoading: false,
      addressReturn: [],
      IDcardFrontLoading: false,
      IDcardBackLoading: false,
      uploadUrl: process.env.VUE_APP_QINIUPHOST,
      wechatLink: '',
      qrcodeScanStatu: false,
      addLoading: false,
      timeCheckboxValue: false
    }
  },
  computed: {
    ...mapState(['qiniuData'])
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    if (query.code) {
      // 绑定微信
      this.stepsCurrent = 2
      const params = {
        id: query.id,
        userType: query.userType,
        code: query.code
      }
      this.lectorBindWX(params)
    } else if (query.status) {
      // 绑定成功
      this.stepsCurrent = 2
      this.qrcodeScanStatu = true
    } else {
      // 常规
      this.form.lectortype = query.lectortype
    }
  },
  methods: {
    handleAddressChange: Debounce(function(e) {
      // 地址搜索
      if (!e) return
      const baseUrl = `https://apis.map.qq.com/ws/place/v1/search?keyword=${e}&key=${process.env.VUE_APP_QQMAPKEY}&output=jsonp&boundary=region(中国)`
      this.$jsonp(baseUrl)
        .then((res) => {
          // console.log(res)
          const data = res.data.map((v) => {
            if (v.address && v.title) {
              return { value: `${v.address}${v.title}` }
            }
          })
          this.addressReturn = data
        })
        .catch((err) => {
          console.log(err)
        })
    }, 500),
    handleAvatarChange(info) {
      // 头像上传
      if (info.file.status === 'uploading') {
        if (this.form.avatar) {
          this.form.avatar = ''
        }
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
        this.form.avatar = this.qiniuData.host + info.file.response.key
        this.avatarLoading = false
      }
    },
    handleIDcardFrontChange(info) {
      // 身份证正面上传
      if (info.file.status === 'uploading') {
        if (this.form.IDcardFrontUrl) {
          this.form.IDcardFrontUrl = ''
        }
        this.IDcardFrontLoading = true
        return
      }
      if (info.file.status === 'done') {
        this.form.IDcardFrontUrl = this.qiniuData.host + info.file.response.key
        this.IDcardFrontLoading = false
      }
    },
    handleIDcardBackChange(info) {
      // 身份证反面上传
      if (info.file.status === 'uploading') {
        if (this.form.IDcardBackUrl) {
          this.form.IDcardBackUrl = ''
        }
        this.IDcardBackLoading = true
        return
      }
      if (info.file.status === 'done') {
        this.form.IDcardBackUrl = this.qiniuData.host + info.file.response.key
        this.IDcardBackLoading = false
      }
    },
    async beforeAvatarUpload(file) {
      // 头像上传检验
      await this.$examineImgWH(file)
    },
    async beforeIdCardUpdate(file) {
      // 身份证上传检验
      await this.$examineImgDirection(file, 'horizontal')
    },
    onStartTimeChange(date, dateString) {
      // 身份证有效期开始日期
      IDcardStartTime = dateString
    },
    onEndTimeChange(date, dateString) {
      // 身份证有效期结束日期
      IDcardEndTime = dateString
    },
    onTimeCheckChange(e) {
      // 监听身份证有效期切换
      if (e.target.checked) {
        this.IDcardEndTimeProp = ''
        this.$refs.ruleForm.clearValidate('IDcardEndTime') // 移除表单项的校验结果
      } else {
        this.IDcardEndTimeProp = 'IDcardEndTime'
      }
    },
    onSubmit() {
      // 下一步/提交
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.stepsCurrent === 1) {
            this.addLoading = true
            const hide = this.$message.loading('请稍后', 0)
            delete this.form.IDcardStartTime
            if (this.timeCheckboxValue) {
              this.form.IDcardEndTime = '长期'
            } else {
              this.form.IDcardEndTime = `${IDcardStartTime} - ${IDcardEndTime}`
            }
            this.form.address = this.form.address.substring(
              0,
              this.form.address.length - 1
            )
            this.$axios.addLector(this.form).then((res) => {
              // 获取绑定微信二维码
              const types = {
                1: 'lector',
                2: 'training'
              }
              const subData = {
                id: res.data.data,
                userType: types[this.form.lectortype]
              }
              this.$axios.getLectorBindWX(subData).then((subRes) => {
                this.wechatLink = subRes.data.data.url
                this.stepsCurrent++
                this.addLoading = false
                hide()
              })
            })
          } else {
            this.stepsCurrent++
          }
        }
      })
    },
    lectorBindWX(data) {
      // 绑定微信
      const hide = this.$message.loading('请稍后', 0)
      this.$axios.lectorBindWX(data).then(() => {
        hide()
        this.qrcodeScanStatu = true
        this.$router.replace({
          query: {
            status: 1
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.body {
  background-color: #fff;
  padding: 48px 0;
}

.ant-steps,
.ant-form {
  width: 755px;
  margin: 0 auto;
}

.ant-form {
  margin-top: 40px;
}

.submit-btn {
  margin-left: 18em;
}

.qrcode-box {
  width: 300px;
  margin: 40px auto 0 auto;
}

.bind-status {
  width: 560px;
  margin: 40px auto 0 auto;
}

.bind-status .icon-box {
  width: 70px;
  height: 70px;
  margin: 30px auto;
}

.bind-title {
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  font-weight: bold;
}

.bind-p {
  text-align: center;
  font-size: 14px;
  height: 22px;
  margin: 8px auto 24px auto;
}

.btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-box .bind-btn:not(:last-child) {
  margin-right: 8px;
}

.avatar {
  display: block;
  width: 88px;
  height: 88px;
}

.ID-card,
.ID-time {
  display: flex;
}

.ID-card {
  padding-left: 6.5em;
}

.ID-card >>> .IDcard-uploader .ant-upload.ant-upload-select-picture-card,
.ID-time >>> .ant-calendar-picker {
  width: 160px;
}

.ID-time {
  padding-left: 5.5em;
}

.ID-time >>> .date-picker-2 {
  margin-left: 8px;
}

.ID-time >>> .checkbox {
  margin-left: 16px;
}
</style>
<style>
.body
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item-description {
  max-width: 200px;
}
</style>
